import React from "react"
import { FormattedMessage, injectIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ intl }) => (
  <Layout>
    <SEO
      lang={intl.locale}
      title={`404: ${intl.formatMessage({ id: "title" })}`}
    />
    <div className="bg-primary text-center p-5 text-white font-weight-bold">
      <h1 className="m-4">
        <FormattedMessage id="notfound.header"/>
      </h1>
    </div>
    <div className="py-5 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <FormattedMessage id="notfound.description"/>
          </div>
        </div>
      </div>
    </div>
    <div className="pb-4 bg-light">
      <div className="container">
        <Link to="/">
          <i className="las la-angle-left"/>
          {intl.formatMessage({ id: "go_back" })}
        </Link>
      </div>
    </div>
  </Layout>
)

export default injectIntl(NotFoundPage)
